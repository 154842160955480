<template>
  <div>
    <calc-result-page>
      <div class="res-box">
        <div>生育保险计算结果为：</div>
        <div>
          <span class="headline">待遇总金额</span>
          <span class="res">{{ res.damage }}</span>
          <span class="unit">元</span>
        </div>
        <div>
          <span class="headline">产假天数</span>
          <span class="res">{{ res.day }}</span>
          <span class="unit">天</span>
        </div>
      </div>

      <fee-consititute-container>
        <res-list-item
          title="生育津贴"
          :content="res.wage + '元'"
        ></res-list-item>
        <res-list-item
          title="产前检查费"
          :content="res.examinationFee + '元'"
        ></res-list-item>
        <res-list-item
          title="手术费"
          :content="res.opsFee + '元'"
        ></res-list-item>
      </fee-consititute-container>

      <div class="tip">以上结果只是测算，最终以医保局计算结果为准</div>
    </calc-result-page>
  </div>
</template>

<script>
import CalcResultPage from "@/components/CalcResultPage";
import ResListItem from "@/views/calc/components/ResListItem";
import FeeConsitituteContainer from "@/views/calc/components/FeeConsitituteContainer.vue";

export default {
  components: {
    CalcResultPage,
    ResListItem,
    FeeConsitituteContainer,
  },
  data() {
    return {
      res: {},
    };
  },
  mounted() {
    const res = this.$route.query.res
    if (res !== undefined) {
      this.res = JSON.parse(this.$route.query.res);
    }
  },
};
</script>

<style scoped>
.res-box {
  margin-left: 20px;
  color: #4d4d4d;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 50px;
}

.res {
  font-size: 44px;
  color: #658CF1;
}

.headline {
  font-size: 16px;
  color: #999999;
  margin-right: 6px;
}

.unit {
  margin-left: 4px;
  font-size: 16px;
  color: #999999;
}

.tip {
  margin-top: 120px;
  margin-left: 40px;
  font-size: 12px;
  color: #658CF1;
}
</style>